import React from 'react';

export default function CareIcon(props) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      {...props}
      xmlSpace='preserve'
    >
      <g>
        <g>
          <circle cx='81.161' cy='398.866' r='10' />
        </g>
      </g>
      <g>
        <g>
          <path d='M461.2,92.95h-58.838c-13.282,0-25.479,4.734-34.994,12.602v-2.602V75.488c0-39.271-31.947-71.218-71.217-71.218h-66.715
			c-5.523,0-10,4.478-10,10v27.463c0,39.27,31.947,71.217,71.217,71.217h56.715v34.994v26.98v39.397
			c-2.638-0.169-5.291-0.262-7.957-0.262c-26.281,0-51.407,8.102-72.657,23.429c-4.479,3.23-5.492,9.48-2.261,13.96
			c3.231,4.479,9.481,5.494,13.96,2.261c17.823-12.854,38.903-19.649,60.958-19.649c5.331,0,10.601,0.422,15.778,1.215
			c0.429,0.095,0.868,0.163,1.316,0.202c31.119,5.174,58.637,24.28,74.234,52.431c1.826,3.295,5.238,5.155,8.756,5.155
			c1.639,0,3.302-0.404,4.837-1.255c4.831-2.677,6.577-8.763,3.9-13.594c-17.304-31.232-46.929-53.113-80.865-60.955v-32.335h48.839
			c30.323,0,54.994-24.67,54.994-54.994v-26.98C471.2,97.428,466.723,92.95,461.2,92.95z M347.368,92.95h-56.715
			c-28.241,0-51.217-22.976-51.217-51.217V24.27h56.715c28.241,0,51.217,22.976,51.217,51.218V92.95z M451.2,129.931
			c0,19.295-15.698,34.994-34.994,34.994h-48.838v-16.98c0-19.295,15.698-34.994,34.994-34.994H451.2V129.931z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M508.662,328.811c-8.728-19.767-31.911-28.744-51.678-20.021c-0.05,0.022-0.099,0.045-0.149,0.067l-65.337,30.131
			c-5.016,2.313-7.206,8.254-4.894,13.269c2.313,5.016,8.254,7.205,13.269,4.894l65.257-30.094
			c9.663-4.222,20.971,0.176,25.235,9.833c4.273,9.679-0.125,21.029-9.803,25.303l-133.706,59.035
			c-21.186,9.355-45.234,8.812-65.977-1.495l-48.944-24.315c-26.08-12.957-56.317-13.641-82.954-1.88l-0.962,0.426l-36.579-82.846
			l50.784-22.422c24.192-10.682,51.188-11.543,76.012-2.428l98.423,36.145c8.979,3.298,13.765,13.343,10.669,22.393
			c-3.155,9.224-13.234,14.184-22.467,11.063l-76.11-25.743c-5.232-1.77-10.907,1.037-12.677,6.269s1.037,10.907,6.269,12.677
			l76.11,25.743c3.985,1.348,8.044,1.988,12.039,1.988c15.698,0,30.409-9.882,35.76-25.521c6.587-19.254-3.595-40.627-22.697-47.642
			l-98.422-36.145c-29.714-10.911-62.026-9.88-90.984,2.904l-51.111,22.567c-2.875-4.418-7.026-7.835-12.037-9.778
			c-6.31-2.444-13.195-2.285-19.36,0.437L5.986,312.392C2.35,313.986,0,317.581,0,321.552V497.73c0,3.473,1.802,6.697,4.76,8.518
			c1.602,0.985,3.418,1.482,5.24,1.482c1.542,0,3.087-0.356,4.508-1.074l127.46-64.389c6.061-2.757,10.716-7.686,13.125-13.905
			c2.007-5.18,2.259-10.747,0.773-16l1.192-0.526c21.188-9.355,45.235-8.809,65.978,1.496l48.944,24.315
			c26.079,12.957,56.315,13.642,82.954,1.879l133.707-59.035C508.408,371.762,517.389,348.578,508.662,328.811z M136.444,421.136
			c-0.515,1.329-1.516,2.378-2.82,2.953c-0.159,0.07-0.315,0.145-0.47,0.223L20,481.474V437.7l29.578-14.525
			c4.958-2.435,7.003-8.427,4.568-13.385s-8.429-7.003-13.384-4.568L20,415.417v-87.33l59.688-26.155
			c1.304-0.575,2.754-0.609,4.082-0.095c1.329,0.515,2.378,1.517,2.954,2.82l47.038,106.532c0.004,0.01,0.008,0.019,0.012,0.028
			l2.577,5.836C136.926,418.357,136.959,419.807,136.444,421.136z'
          />
        </g>
      </g>
    </svg>

  );
}
