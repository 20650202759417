import React from 'react';
import styled from 'styled-components';
import uvodniFoto from '../../icons/uvodniFoto.png';

function MainHeaderImage() {
  return (
    <PageWrapper>
      <ImageContainer>
        <Image src={uvodniFoto} />
      </ImageContainer>
      {/* <RedLine /> */}
    </PageWrapper>
  );
}

const ImageContainer = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  position: relative;
`;
const Image = styled.img`
  width: 100%;
`;
const PageWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  position: relative;
  `;

const RedLine = styled.div`
  background: ${({ theme }) => theme.palette.primary.light};
  height: 107px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 150px;
  z-index: -1;
`;

export default MainHeaderImage;
