import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
//
import finance from '../../assets/finance.jpg';
import konzultace from '../../assets/konzultace.jpg';
import refinancovani from '../../assets/refinancovani.jpg';

export default function HowCanWeHelp() {
  return (
    <PageWrapper>
      <h1> S čím Vám můžeme POMOCI? </h1>
      <ContentBox>
        <Box onClick={() => navigate('/uvery')}>
          <h3>Zajištění úvěru</h3>
          <Link to='/uvery'>
            <Text>
              <p>Financujte</p>
            </Text>
          </Link>
          <MoneyPic src={finance} />
        </Box>
        <Box onClick={() => navigate('/refinancovani')}>
          <h3>Refinancování úvěru</h3>
          <Link to='/refinancovani'>
            <Text>
              <p>Refinancujte</p>
            </Text>
          </Link>
          <MoneyPic src={refinancovani} />
        </Box>
        <Box onClick={() => navigate('/sluzby')}>
          <h3>Konzultace</h3>
          <Link to='/sluzby'>
            <Text>
              <p>Poraďte se</p>
            </Text>
          </Link>
          <MoneyPic src={konzultace} />
        </Box>
      </ContentBox>
    </PageWrapper>
  );
}

const Text = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,0);
  background: rgba(255,0,0, .6);
  padding: 0 12px;

  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 600;
  }
`;

const MoneyPic = styled.img`
  width: 100%;
  border-radius: 0 0 15px 15px;
  margin-bottom: -5px;
`;

const PageWrapper = styled.div`
  padding: 24px;
`;
const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  flex-flow: row wrap;
`;
const Box = styled.div`
  width: 320px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
  margin: 24px 36px;
  position: relative;
  transition: all .6s ease;
  cursor: pointer;

  @media(max-width: 450px) {
    margin: 24px 0;
  }

  &:hover {
    transform: scale(1.1);

    @media(max-width: 450px) {
      transform: scale(1);
    }
  }
`;
