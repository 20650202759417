import React from 'react';
import styled from 'styled-components';
import Specialization from '../../icons/SpecializationIcon';
import Partners from '../../icons/PartnersIcon';
import Begining from '../../icons/BeginingIcon';
import SaveTime from '../../icons/SaveTimeIcon';
import CheckingFormIcon from '../../icons/CheckingFormIcon';
import CareIcon from '../../icons/CareIcon';
import leftHand from '../../icons/leftHand.png';

function Advantage() {
  return (
    <PageWrapper>
      <h1> Jaké jsou naše VÝHODY? </h1>
      <BoxWrap>
        <Box>
          <IconBox>
            <Specialization />
          </IconBox>
          <TextBox>
            <h3>Specializace</h3>
            <p>Zaměření jen na bytové domy a související produkty.</p>
          </TextBox>
        </Box>
        <Box>
          <IconBox>
            <Partners />
          </IconBox>
          <TextBox>
            <h3>Partneři</h3>
            <p>Spolupracujeme s většinou bankovních domů a stavebních spořitelen v ČR.</p>
          </TextBox>
        </Box>
        <Box>
          <IconBox>
            <Begining />
          </IconBox>
          <TextBox>
            <h3>Komplexnost</h3>
            <p>Provedeme Vás financemi od první myšlenky až po dokončení projektu.</p>
          </TextBox>
        </Box>
        <Box>
          <IconBox>
            <SaveTime />
          </IconBox>
          <TextBox>
            <h3>Úspora Vašeho času</h3>
            <p>Zajistíme přípravu potřebné dokumentace a vyřídíme komunikaci s partnery.</p>
          </TextBox>
        </Box>
        <Box>
          <IconBox>
            <CheckingFormIcon />
          </IconBox>
          <TextBox>
            <h3>Vyhodnocení</h3>
            <p>Připravíme Vám vyhodnocení všech variant nabídek.</p>
          </TextBox>
        </Box>
        <Box>
          <IconBox>
            <CareIcon style={{ width: 50, height: 50 }} />
          </IconBox>
          <TextBox>
            <h3>Odborná péče</h3>
            <p>Zkontrolujeme a předáme potřebné podklady financující instituci.</p>
          </TextBox>
        </Box>
      </BoxWrap>
      <Hand src={leftHand} />
    </PageWrapper>
  );
}

const Hand = styled.img`
  position: absolute;
  left: 0;
  bottom: auto;
  max-width: 200px;
  @media(max-width: 700px) {display: none}
`;
const BoxWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: row wrap;
  position: relative;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  p { margin: 2px; width: 100%; }

  h3 { margin: 2px 0 6px 0; }
`;

const IconBox = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 100px;
  padding: 12px;
  margin-right: 12px;
`;

const Box = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: flex-start;
  width: 500px;

  @media(max-width: 500px) { width: 96%; }
`;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 2%;
  position: relative;

  @media(max-width: 900px) {
    width: 96%;
    padding: 0;
  }
`;

export default Advantage;
