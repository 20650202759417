import React from 'react';
import { withSiteData } from 'react-static';
// import styled from 'styled-components';
import MainHeaderImage from '../components/MainHeaderImage';
import Advantage from '../components/Advantage';
import HowCanWeHelp from '../components/HowCanWeHelp';

export default withSiteData(() => (
  <div>
    <MainHeaderImage />
    <Advantage />
    <HowCanWeHelp />
  </div>
));
