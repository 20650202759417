import React from 'react';

export default function BeginingIcon() {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='601.000000pt'
      height='593.000000pt'
      viewBox='0 0 601.000000 593.000000'
      preserveAspectRatio='xMidYMid meet'
      style={{ width: 50, height: 50 }}
    >
      <g
        transform='translate(0.000000,593.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path d='M3020 4905 l0 -655 115 0 115 0 0 529 0 530 38 -5 c125 -17 410 -80
422 -93 3 -3 14 -7 25 -9 36 -6 249 -93 340 -140 165 -85 380 -226 370 -243
-3 -4 0 -11 6 -15 8 -4 9 -3 5 4 -10 17 0 15 26 -5 140 -113 312 -288 302
-305 -4 -6 -2 -8 3 -5 31 19 253 -300 351 -506 91 -189 120 -265 164 -427 57
-214 73 -354 72 -635 0 -273 -22 -419 -98 -665 -55 -174 -147 -390 -167 -390
-6 0 -8 -4 -4 -9 10 -17 -131 -242 -235 -375 -91 -116 -258 -282 -390 -388
-80 -64 -174 -128 -188 -128 -7 0 -12 -4 -12 -8 0 -18 -231 -138 -380 -199
-655 -266 -1432 -214 -2040 139 -96 56 -271 174 -329 223 -127 106 -282 265
-274 279 4 5 1 6 -4 3 -6 -4 -23 10 -39 31 -15 20 -30 39 -33 42 -37 33 -153
209 -146 220 4 6 2 9 -4 8 -5 -2 -25 23 -44 54 -42 68 -157 302 -157 318 0 6
-9 29 -21 52 -11 22 -18 49 -16 59 3 11 1 19 -3 19 -14 0 -63 178 -90 330 -6
36 -16 87 -21 115 -11 58 -13 570 -2 588 4 6 8 30 10 52 2 22 9 60 15 85 7 25
11 49 10 54 -5 15 30 136 39 136 4 0 5 8 2 18 -2 10 5 43 16 73 12 30 21 60
21 67 0 7 9 32 20 54 11 23 20 46 20 51 0 27 153 318 223 422 102 154 195 267
210 259 7 -4 9 -4 5 1 -4 4 14 32 39 62 l47 54 -83 82 -82 82 -80 -84 c-185
-197 -341 -434 -472 -721 -31 -67 -103 -272 -117 -330 -5 -19 -15 -62 -24 -95
-57 -224 -85 -541 -67 -760 6 -77 13 -162 15 -190 2 -27 6 -57 9 -65 4 -14 21
-92 31 -150 5 -30 19 -85 40 -159 8 -30 13 -58 10 -63 -3 -4 -1 -8 4 -8 9 0
50 -121 43 -129 -1 -2 1 -7 6 -10 4 -3 19 -33 32 -66 59 -146 198 -400 272
-496 22 -28 26 -39 16 -49 -9 -10 -9 -11 1 -6 17 10 44 -13 38 -31 -3 -8 -2
-12 3 -9 5 4 33 -24 62 -61 81 -104 253 -276 364 -366 55 -44 101 -84 103 -88
2 -5 7 -9 12 -9 4 0 54 -31 111 -69 125 -82 255 -154 364 -201 44 -19 82 -37
85 -40 3 -3 14 -6 25 -8 11 -2 39 -12 62 -23 24 -10 50 -19 59 -19 8 0 24 -5
35 -10 23 -13 196 -59 259 -70 25 -4 83 -15 130 -23 225 -41 597 -39 835 5 33
6 76 12 96 13 20 1 40 5 45 8 10 6 89 29 119 34 11 2 46 13 78 24 32 11 66 18
75 14 9 -3 13 -3 9 1 -8 8 198 96 230 98 11 0 19 4 17 7 -2 3 40 28 94 54 219
110 485 308 667 496 297 308 502 654 623 1049 18 58 37 119 43 135 6 17 11 37
11 45 0 8 9 55 19 104 11 50 16 99 13 109 -4 11 -2 17 3 14 22 -14 31 476 12
651 -12 117 -43 281 -70 377 -26 89 -76 244 -89 270 -5 11 -21 49 -35 85 -66
162 -210 407 -329 559 -87 112 -96 122 -235 261 -184 183 -356 314 -577 437
-103 57 -105 58 -329 152 -37 16 -68 26 -247 81 -37 11 -78 20 -92 20 -14 0
-35 4 -48 9 -13 5 -51 15 -85 22 -35 6 -60 16 -57 20 3 5 0 9 -5 9 -6 0 -11
-7 -11 -15 0 -16 -16 -20 -24 -6 -3 5 -54 12 -113 15 -60 3 -138 8 -175 11
l-68 5 0 -655z'
        />
        <path d='M1250 3921 c-46 -25 -95 -78 -107 -116 -11 -36 -9 -146 3 -168 5 -8
24 -31 41 -51 85 -92 252 -63 305 54 14 30 26 160 16 160 -5 0 -8 7 -8 15 0
27 -70 94 -109 104 -79 21 -105 21 -141 2z m125 -132 c47 -89 -30 -188 -98
-125 -21 19 -27 34 -27 65 0 55 8 71 43 86 39 16 65 9 82 -26z'
        />
        <path d='M1566 3905 c-12 -12 -14 -44 -10 -182 3 -92 8 -171 10 -175 8 -12
163 -10 209 3 58 16 92 53 106 114 10 44 9 71 -3 167 -1 9 -5 15 -9 13 -3 -3
-19 9 -34 25 -31 32 -47 36 -172 44 -64 4 -85 2 -97 -9z m178 -105 c43 -16 48
-116 8 -147 -9 -7 -32 -13 -49 -13 l-33 0 0 91 0 91 25 -6 c13 -3 35 -10 49
-16z'
        />
        <path d='M3155 3808 c-25 -15 -45 -34 -45 -45 0 -4 -18 -30 -40 -58 -29 -36
-40 -59 -40 -84 0 -19 3 -31 6 -28 4 4 19 -2 34 -13 38 -27 48 -25 83 15 16
19 34 32 40 28 5 -3 7 -1 3 5 -6 10 26 75 51 100 19 19 16 28 -17 62 -32 33
-44 36 -75 18z'
        />
        <path d='M2378 3764 c-47 -30 -48 -50 -3 -108 19 -26 35 -50 35 -55 0 -5 14
-23 32 -40 51 -50 85 -41 142 39 22 30 41 48 48 44 6 -4 8 -3 5 3 -4 6 0 25 9
42 18 37 11 51 -46 84 l-31 19 -36 -40 -36 -41 -29 40 c-15 21 -34 39 -41 38
-6 0 -29 -11 -49 -25z'
        />
        <path d='M1855 3523 c-63 -8 -86 -24 -112 -82 -23 -52 -55 -138 -88 -237 -7
-23 -20 -55 -28 -71 -8 -15 -18 -45 -21 -66 -3 -21 -15 -55 -26 -77 -40 -79
-25 -111 55 -116 93 -5 113 2 125 47 14 50 33 59 124 59 73 0 76 -1 76 -24 0
-13 9 -35 21 -50 18 -23 27 -26 83 -26 108 0 127 33 80 136 -14 31 -28 72 -31
90 -3 19 -10 34 -14 34 -5 0 -9 7 -9 15 0 8 -13 43 -29 77 -15 35 -34 88 -41
118 -25 108 -34 123 -86 151 -30 15 -61 24 -79 22z'
        />
        <path d='M2389 3487 c-69 -35 -132 -105 -149 -167 -14 -48 -13 -234 2 -282 16
-53 75 -118 134 -148 38 -19 68 -26 136 -28 83 -4 89 -3 130 26 64 44 128 113
128 139 0 25 -50 68 -94 79 -24 6 -33 1 -65 -34 -21 -22 -50 -44 -65 -48 -42
-10 -91 18 -116 65 -22 44 -27 87 -8 75 7 -4 8 -3 4 5 -4 6 -12 8 -18 5 -13
-8 -1 81 14 105 18 29 77 61 112 61 27 0 41 -8 70 -40 42 -46 70 -51 112 -18
63 48 67 71 27 128 -33 45 -84 77 -156 96 -83 22 -128 18 -198 -19z'
        />
        <path d='M1041 3491 c-16 -16 -21 -33 -21 -70 0 -84 -5 -81 146 -81 l134 0
-102 -100 c-57 -55 -106 -100 -109 -100 -4 0 -14 -12 -24 -26 -9 -14 -30 -40
-47 -57 -27 -29 -29 -36 -26 -99 1 -37 4 -65 6 -63 3 2 12 0 22 -5 10 -5 104
-10 210 -11 106 0 206 -5 223 -10 19 -5 35 -5 42 1 18 15 37 120 25 135 -5 6
-8 14 -6 16 3 2 -48 3 -112 1 -64 -2 -130 -1 -147 2 l-31 6 117 118 c64 64
129 127 144 139 33 26 36 35 42 120 4 47 1 64 -9 68 -7 2 -92 9 -188 15 -96 5
-196 13 -221 16 -39 5 -50 3 -68 -15z'
        />
        <path d='M3021 3474 c-17 -14 -31 -36 -31 -47 0 -12 -7 -30 -15 -41 -7 -10
-22 -44 -31 -75 -9 -31 -21 -60 -26 -66 -4 -5 -8 -17 -8 -27 0 -10 -9 -37 -19
-61 -11 -23 -22 -52 -26 -64 -3 -13 -10 -23 -15 -23 -4 0 -6 -8 -3 -18 2 -10
-7 -44 -21 -75 -47 -101 -34 -120 80 -118 82 1 103 13 104 57 0 36 20 44 112
44 l88 0 0 -28 c0 -41 32 -65 95 -72 47 -5 57 -3 85 20 29 23 31 29 25 63 -4
20 -10 37 -14 37 -8 0 -22 33 -54 131 -15 42 -37 101 -51 130 -13 30 -30 79
-37 109 -21 93 -34 116 -79 134 -62 25 -122 21 -159 -10z'
        />
        <path d='M3457 3484 c-45 -25 -62 -98 -32 -140 8 -12 13 -23 10 -25 -6 -7 90
-2 112 5 21 7 22 4 20 -88 -1 -52 -5 -97 -8 -101 -4 -3 -3 -24 2 -46 5 -22 7
-45 3 -50 -11 -19 -7 -149 6 -164 9 -10 32 -15 75 -15 57 0 66 3 85 27 17 22
20 34 14 68 -4 22 -7 47 -5 55 1 8 3 77 6 153 2 77 6 141 9 145 3 3 0 12 -7
20 -11 13 -4 15 57 9 45 -4 74 -3 83 4 14 12 18 106 5 126 -5 8 -63 14 -182
18 -96 3 -186 8 -200 10 -14 2 -38 -2 -53 -11z'
        />
        <path d='M3982 3467 c-12 -14 -29 -539 -18 -580 12 -48 130 -62 171 -20 14 13
15 29 10 88 -7 71 -7 73 25 108 18 21 37 37 44 37 11 0 36 -53 36 -78 0 -7 12
-30 26 -52 15 -23 23 -44 18 -52 -4 -7 -4 -10 1 -6 4 4 21 -11 37 -33 l29 -40
81 3 c68 3 83 6 94 23 12 19 10 26 -19 71 -19 28 -36 58 -40 67 -3 9 -29 50
-57 90 -27 41 -50 78 -50 83 0 4 -9 18 -20 30 l-21 21 42 34 c23 19 73 65 111
102 90 88 85 97 -52 97 l-97 0 -54 -57 c-30 -32 -70 -82 -88 -110 -51 -81 -64
-69 -48 43 13 91 6 119 -34 133 -43 15 -114 14 -127 -2z'
        />
        <path d='M4633 3468 c-23 -11 -23 -14 -24 -217 0 -113 -2 -219 -2 -236 -1 -16
3 -35 8 -41 6 -6 24 -27 40 -48 51 -65 101 -87 197 -88 94 -1 145 13 202 56
67 51 74 81 79 331 5 190 3 222 -10 238 -27 29 -142 18 -166 -16 -4 -7 -7 -86
-6 -177 2 -237 3 -234 -27 -263 -32 -33 -57 -31 -102 9 -33 29 -35 33 -22 50
8 10 10 15 4 11 -7 -4 -14 2 -18 16 -7 27 -8 92 -1 99 3 2 7 62 9 132 3 119 2
128 -18 141 -24 17 -110 19 -143 3z'
        />
        <path d='M2776 2763 c-15 -3 -44 -13 -63 -24 -19 -11 -39 -16 -45 -13 -6 4 -8
3 -5 -3 3 -5 -16 -32 -44 -59 -70 -69 -89 -125 -89 -261 0 -119 13 -158 72
-224 46 -51 84 -75 153 -94 73 -22 157 -16 220 15 83 40 175 151 175 210 0 12
3 19 8 17 11 -7 15 152 4 182 -5 14 -15 44 -21 67 -15 57 -100 141 -158 157
-24 6 -43 14 -43 17 0 4 -24 7 -52 9 -29 1 -60 3 -68 5 -8 1 -28 1 -44 -1z
m148 -212 c20 -20 27 -37 23 -50 -3 -12 -2 -19 3 -16 28 17 26 -99 -2 -155
-40 -77 -131 -91 -189 -29 -60 64 -59 182 2 245 29 30 39 34 84 34 44 0 55 -4
79 -29z'
        />
        <path d='M1465 2747 c-78 -31 -124 -105 -125 -200 0 -90 51 -171 117 -183 18
-3 31 -9 28 -13 -2 -4 23 -4 56 1 71 9 110 34 146 92 23 35 27 52 27 118 0 68
-3 80 -29 113 -45 61 -156 96 -220 72z m119 -144 c9 -19 15 -43 12 -52 -2 -9
-7 -28 -10 -42 -8 -38 -57 -57 -90 -36 -37 25 -51 77 -32 121 27 64 92 68 120
9z'
        />
        <path d='M4120 2744 c-88 -33 -140 -77 -184 -157 -24 -45 -26 -56 -26 -177 0
-154 14 -196 89 -261 67 -59 103 -72 201 -73 75 -1 90 2 131 26 54 32 139 122
139 147 0 23 -36 56 -83 76 -43 18 -64 9 -90 -35 -21 -35 -73 -51 -117 -36
-39 12 -84 71 -76 98 2 10 0 16 -5 12 -12 -7 -12 98 0 129 6 14 31 40 56 57
56 39 79 35 136 -20 23 -22 48 -40 56 -40 29 0 78 31 100 62 20 29 21 33 7 60
-43 83 -125 128 -243 134 -42 2 -83 1 -91 -2z'
        />
        <path d='M4559 2745 c-1 -3 -2 -8 -3 -12 -1 -5 -4 -12 -8 -18 -9 -14 -13 -576
-5 -602 12 -33 51 -41 152 -32 50 4 142 8 206 8 87 1 119 5 128 15 7 9 11 41
9 83 l-3 68 -60 5 c-33 3 -100 1 -150 -3 -100 -9 -117 -1 -110 54 6 48 8 49
133 42 135 -7 141 -4 150 72 8 71 0 75 -150 75 l-129 0 3 38 3 37 132 0 c92 0
136 4 144 12 12 12 14 79 3 115 -3 9 -16 24 -31 33 -14 9 -22 12 -18 5 5 -9
-41 -11 -193 -9 -110 2 -198 7 -196 11 3 5 3 8 0 8 -3 0 -7 -2 -7 -5z'
        />
        <path d='M971 2722 c-13 -25 -12 -332 2 -349 12 -16 155 -16 216 0 31 8 53 23
74 50 27 33 31 46 35 122 l5 85 -50 50 c-50 50 -50 50 -115 50 -36 0 -86 3
-111 6 -39 6 -47 4 -56 -14z m191 -103 c21 -11 32 -66 23 -108 -8 -35 -30 -51
-74 -51 l-30 0 2 90 2 90 30 -6 c17 -4 38 -10 47 -15z'
        />
        <path d='M3315 2732 c-49 -4 -78 -27 -65 -51 5 -10 12 -144 16 -299 6 -233 10
-284 22 -295 20 -16 127 -14 148 4 14 12 16 32 11 164 -4 83 -9 159 -13 168
-3 9 -2 17 3 17 5 0 15 -17 22 -37 8 -21 17 -40 20 -43 3 -3 28 -50 56 -105
27 -55 65 -117 83 -137 33 -36 37 -38 96 -38 33 0 68 6 77 13 14 10 17 54 22
310 5 204 3 305 -4 319 -9 18 -17 19 -77 12 -109 -11 -106 -7 -98 -143 4 -64
9 -136 12 -161 l6 -45 -20 35 c-10 19 -38 71 -62 115 -23 44 -51 92 -61 107
-11 14 -19 30 -19 35 0 4 -8 18 -18 29 -18 23 -80 33 -157 26z'
        />
        <path d='M1912 2718 c-9 -9 -12 -91 -12 -309 0 -344 -4 -329 88 -329 91 0 102
13 102 119 0 86 0 87 35 121 19 18 36 32 38 29 2 -2 12 -26 22 -54 20 -55 78
-158 109 -193 18 -20 28 -23 93 -21 41 1 76 6 79 11 3 4 9 6 14 3 5 -3 12 5
16 18 4 18 -3 38 -29 73 -18 27 -41 65 -50 84 -8 19 -27 46 -41 59 -13 12 -30
37 -37 54 -7 18 -25 46 -41 63 l-29 31 31 27 c18 14 36 24 41 20 5 -3 8 -2 7
3 -2 5 30 42 70 83 39 41 72 80 72 87 0 18 -32 24 -126 25 l-85 1 -39 -38
c-21 -20 -60 -69 -86 -108 -64 -94 -72 -103 -80 -83 -6 16 -4 31 14 126 10 52
-8 95 -42 104 -37 10 -122 6 -134 -6z'
        />
      </g>
    </svg>

  );
}
